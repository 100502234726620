import '../styles/global.scss'
import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { LocationOutline, MailOpenOutline, CallOutline } from 'react-ionicons'


// styles


// markup
const IndexPage = () => {
  return (
    <main className='pageStyles'>
      <StaticImage src="../images/logo.jpeg" alt="" />
      <h2>COMING SOON</h2>
      <div className="meta">
        <span className="col"> 
        <LocationOutline
          color={'#BB2B7D'} 
          height="25px"
          width="25px"
        />
        27 G-ral Dragalina st. Timisoara Romania</span>
        <a className="col" href="mailto:madalina.preda@beautyspotmedical.ro">
          <MailOpenOutline
            color={'#BB2B7D'} 
            height="25px"
            width="25px"
          />
          madalina.preda@beautyspotmedical.ro
          </a>
        <a className="col" href="tel:+40723360561">
          <CallOutline
            color={'#BB2B7D'} 
            height="25px"
            width="25px"
          />
          +40 723 360 561
          </a>
      </div>
    </main>
  );
}

export default IndexPage
